import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A now famous surgeon practicing in one of our metro cities was posted to labour ward during his house surgeonship. On the first day as soon as he entered the labour ward, he was directed to conduct a delivery. As the lady was bearing down, this doctor was getting anxious and was fumbling.`}</p>
    <p>{`The lady who turned out to be 4th para, said to him: "Beta, fikar mat karo. Woh apne aap aa jayega bahar, sirph aap pakadne ka he" `}{`[Son, don't fret. The baby will come out on its own, you just have to catch]`}{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      